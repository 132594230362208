.ms-modal {
  width: 450px !important;
  max-width: 95% !important;
}

.ms-modal__title {
  margin-bottom: 15px !important;
}

.ms-modal__figure {
  width: 120px !important;
  border: none !important;
  box-shadow: none !important;
}

.ms-form__group {
  margin-bottom: 5px !important;
}

.ms-form__flex {
  align-items: end !important;
}

.ms-modal__custom-field-container {
  display: inline-block !important;
  align-items: space-between !important;
  width: 50% !important;
}

.ms-modal__custom-field-container + .ms-modal__custom-field-container {
  padding-left: 10px !important;
}

.ms-form__button {
  margin-top: 15px !important;
}

.ms-modal__social-divider {
  display: none !important;
}

.ms-modal__social-buttons {
  margin-top: 10px !important;
}

.ms-modal__footer {
  display: none !important;
}
